<template>
  <div id="order-view-action-edit">
    <b-button id="order-view-action-edit" v-ripple.400 class="mb-75" block @click.prevent="quoteEditable = true">
      <feather-icon :icon="btnIcon || 'EditIcon'" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.edit') }} </span>
    </b-button>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'OrderViewActionEdit',

  props: {
    btnText: {
      type: String,
      default: '',
    },

    btnIcon: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapFields('quote', ['quoteEditable']),
  },
}
</script>
